import React, {  } from 'react';
import { Box, Text, Flex, Square } from '@chakra-ui/react';

interface InsightRating {
  rating: number;
  text: string;
}

interface InsightsSummaryCategoryProps {
  insights: InsightRating[];
  ratingLabels: string[];
  title: string;
}

const InsightsSummaryCategory: React.FC<InsightsSummaryCategoryProps> = ({ insights: feelings, ratingLabels, title }) => {
  const sortedFeelings = feelings.sort((a, b) => b.rating - a.rating);

  const getBackgroundColor = (rating: number) => {
    // Define the shades of background color based on rating
 
    if (rating > 25 && rating <= 50) {
      return  '#a1a1c4';

    }
    else if (rating > 50 && rating <= 75) {
      return  '#616199';
    }
    else if (rating > 75) {
      return  '#36367c';
    }
    else
    {
      return  '#a1a1c4';
    }

  };

  const getRatingLabel = (rating: number) => {
    if (rating > 25 && rating <= 50) {
      return  ratingLabels[1];

    }
    else if (rating > 50 && rating <= 75) {
      return  ratingLabels[2];
    }
    else if (rating > 75) {
      return  ratingLabels[3];
    }
    else
    {
      return  ratingLabels[0];
    }

  };

  if (sortedFeelings.length === 0) {
    return null;
  }
  return (
    <Box pl="5px" pr="5px">
      <Text fontSize="lg" fontWeight="bold" color="gray.700">
        {title}
      </Text>
      {sortedFeelings.map((feeling, index) => (
        <Flex
          key={index}
          mt="2"
          p={2}
          shadow="md"
          borderRadius="md"
          alignItems="center"
          bgColor="#f5f6f8"
          
        >
          <Square alignItems={"center"} justifyContent="center" shadow="md" padding="1" bg={getBackgroundColor(feeling.rating)} borderRadius="5" color="white" fontWeight="bold">
            
            {getRatingLabel(feeling.rating)}
          </Square>
          <Box ml={4}>
            <Text fontWeight="semibold">{feeling.text}</Text>
          </Box>
        </Flex>
      ))}
    </Box>
  );
};

export default InsightsSummaryCategory;

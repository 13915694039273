import { useState } from "react";
import {
    Button,
    Flex, Heading
} from '@chakra-ui/react'
import { lavenderStyle, Theme } from "../../styles/styles";
import { t } from "i18next";
import { Link as Routerlink,   } from "react-router-dom";

function ErrorScreen() {
    const [theme] = useState<Theme>(lavenderStyle)


    return (
        <Flex flexDirection='column' maxHeight="97svh" height='100vh' width="96%" alignItems="center" justifyContent={'center'} marginLeft="auto" marginRight="auto" marginBottom="20px" >
                <Flex width={"100%"} justifyContent="center">
                    <Heading size='lg' paddingTop='5px'  marginLeft="5px">
                        {t('ErrorScreen_title')}
                    </Heading>
            </Flex>
            <Flex flexDir="column" mt="5" maxWidth={"900px"} justifyContent="center" alignItems={"center"}>
               <Button as={Routerlink} to='/homeScreen' colorScheme={theme.colorScheme}> {t('ErrorScreen_go_home_button')}</Button>
            </Flex>
        </Flex> 
     );
}



export default ErrorScreen
import { ArrowBackIcon } from "@chakra-ui/icons";
import { IconButton, useBreakpointValue } from "@chakra-ui/react";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { lavenderStyle, Theme } from "../../../styles/styles";


interface PreviousScreenButtonProps {
    previousScreen?: string;
    onClick?: () => void;
}

const PreviousScreenButton = (props: PreviousScreenButtonProps) => {
    const [theme] = useState<Theme>(lavenderStyle)
    const navigate = useNavigate();
    const buttonSize = useBreakpointValue({ base: "md", sm: "lg", md: "lg" }); // Use useBreakpointValue hook to set different widths based on screen size


    const handleButtonClick = () => {
        if (props.onClick) {
            props.onClick();
            return
        }
        if (props.previousScreen) {
            navigate(props.previousScreen);
        }
    }

    return (
        <IconButton aria-label="Go back"
            variant="ghost"
            colorScheme={theme.colorScheme}
            icon={<ArrowBackIcon boxSize="9" />}

            size={buttonSize}
            borderRadius={theme.borderRadius} onClick={handleButtonClick}> </IconButton>

    );
};

export default PreviousScreenButton;

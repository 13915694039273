import * as ReactDOM from "react-dom/client"
import { App } from "./App"
import "./index.css"
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
        resources: {
                en: {
                  translation: require('./locales/en/translation.json')
                },
                nl: {
                  translation: require('./locales/nl/translation.json')
                }
              },
        lng: 'en', // default language
        fallbackLng: 'en', // fallback language
        interpolation: {
                escapeValue: false // not needed for Chakra UI
        }
});

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
        <I18nextProvider i18n={i18n}>

                <App />
        </I18nextProvider>
)

import {Stripe, loadStripe} from '@stripe/stripe-js';

let stripePromise: Stripe | null

const initializeStripe = async () => {
    if (!stripePromise) {
        stripePromise = await loadStripe(
            'pk_live_51NShwsEC7qjZh8n0jF9RANnSwcqeGjHQ9Sz2CqK27j3spdIiqJbjQEoEbHdAKIzJjp2mqPY0iHvZvOMkgu95QJOT00qq8plhhR'
        );
    }
    return stripePromise;
};

export default initializeStripe;

import React, { createContext } from 'react';
import FirebaseInterface from '../services/firebase';

// Create the Firebase context
const FirebaseContext = createContext<FirebaseInterface>(new FirebaseInterface());

// Define the Firebase provider component
const FirebaseProvider = ({ children }: any) => {
  const firebaseInstance = new FirebaseInterface();

  return (
    <FirebaseContext.Provider value={firebaseInstance}>
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseContext, FirebaseProvider };

// theme.js
// theme.js

// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react'



// 2. Add your color mode config
let theme = extendTheme({
  colors: {
    lavenderStyle: {
      50: '#f6f6ff',
      100: '#e1e1f0',
      200: '#cccce1',
      300: '#b6b6d3',
      400: '#a1a1c4',
      500: '#8b8bb6',
      600: '#7676a7',
      700: '#616199',
      800: '#4c4c8a',
      900: '#36367c',
    },


  },

  components: {
  },
})


// Ofwel maak ik een theme selecter object, ofwel maak ik 2 verschillende themes

export default theme
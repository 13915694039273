import { useState } from "react";
import { Circle, Flex, Text } from "@chakra-ui/react";
import { lavenderStyle, Theme } from "../../styles/styles";

interface MoodTrackerIconProps {
  moodRating: number;
}

function MoodtrackerIcon(props: MoodTrackerIconProps) {
  const [theme] = useState<Theme>(lavenderStyle);

  // Define a color mapping based on mood ratings
  const moodColors = {
    0: "white",       // No Mood (White)
    1: "#FF6868",
    2: "#FF7A7A",
    3: "#FF8C8C",
    4: "#FF9E9E",    // Low Mood (Even Darker Red)
    5: "#FFD966",     // Moderate Mood (Light Yellow)
    6: "#FFD355",     // Moderate Mood (Slightly Darker Yellow)
    7: "#C7D88B",     
    8: "#A2C971",     // High Mood (Light Green)
    9: "#7EBF57",     // High Mood (Slightly Darker Green)
    10: "#7EBF57",    // High Mood (Darker Green)
  };



  const color = (moodColors as any)[Math.round( props.moodRating)] || "gray.300";


  return (
    <Flex justifyContent="center" mt="5px">
      <Circle size="30px" bg={color} color="white" border='1px solid black' borderColor={"blackAlpha.400"}>
        <Text fontSize="md" fontWeight="bold">
          {props.moodRating > 0 ? props.moodRating : ""}
        </Text>
      </Circle>
    </Flex>
  );
}

export default MoodtrackerIcon;

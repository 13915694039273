import React, { useEffect } from 'react';
import { Mood } from '../../types/types';

interface LineChartProps {
    dailyMoods: Mood[];
    width: number;
    height: number;
}

const MoodTrackingGraph: React.FC<LineChartProps> = ({ dailyMoods, width, height }) => {
    

    function timeToPercentage(timeString: string) {
        const [hour, minute, second] = timeString.split(':').map(Number);
    
        const totalSecondsInDay = 24 * 60 * 60;
        const timeInSeconds = hour * 3600 + minute * 60 + second;
        
        const percentage = (timeInSeconds / totalSecondsInDay) ;
        return Number(percentage.toFixed(2)); // Return the percentage rounded to 2 decimal places
    }


    useEffect(() => {
        const moodRatings = []
        dailyMoods.forEach(mood => {
            moodRatings.push(mood.moodRating)
        })


    }, [dailyMoods])

    const chartXOffset = 20;
    const chartYOffset = 20;

    const xLabels= ["00:00", "06:00", "12:00", "18:00","24:00"]
    const xStep =  chartXOffset+ (width / (xLabels.length +2 ) + 5);
    const yStep = (height - chartYOffset )/ (10);

    const points = dailyMoods.map((mood, index) => ({
        x: timeToPercentage(mood.localeTimeString) * (width-30) + chartXOffset,
        y: height -chartYOffset - (mood.moodRating - 1) * yStep - 5 ,
    }));


 

    const path = `M${points.map(point => `${point.x},${point.y}`).join(' L')}`;

    const xAxis = (
        <>
            <line x1={chartXOffset} y1={height-chartYOffset} x2={width-10} y2={height-chartYOffset} stroke="black" />
        

            {xLabels.map((n, index) => (
                <text
                    key={index}

                    x={ 5+ index * xStep }
                    y={height-5} // Adjust this value to control label position

                    fontSize="10px" // Adjust font size if needed
                >
                    {n} {/* Display hour label */}
                </text>
            ))}

        </>
    );

    const yAxis = (

        <>
            <line x1={chartXOffset} y1={0} x2={chartXOffset} y2={height-chartYOffset} stroke="black" />
            {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((n, index) => (
                <text
                    key={index}

                    x={n < 10 ? 2 : 0}
                    y={index * yStep + 15 } // Adjust this value to control label position

                    fontSize="10px" // Adjust font size if needed
                >
                    {n} {/* Display hour label */}
                </text>
            ))}
        </>

    );

    const dataPoints = (
        <>
            {points.map((point, index) => (
                <g key={index}>
                    <circle cx={point.x} cy={point.y} r={3} fill="red" /> {/* Red circle */}
                    <line x1={point.x - 5} y1={point.y - 5} x2={point.x + 5} y2={point.y + 5} stroke="red" /> {/* Diagonal line of cross */}
                    <line x1={point.x - 5} y1={point.y + 5} x2={point.x + 5} y2={point.y - 5} stroke="red" /> {/* Diagonal line of cross */}
                </g>
            ))}
        </>
    );

    return (
        <svg width={width} height={height}>
            {xAxis}
            {yAxis}
            <path d={path} stroke="#4b4b6f" fill="none" />
            {dataPoints} {/* Render the red crosses */}
        </svg>
    );
};

export default MoodTrackingGraph;

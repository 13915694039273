import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';




interface InsightsSummaryCategoryProps {
  plannedActions: string[];
  title: string;
}

const InsightsSummaryPlannedActionsCategory: React.FC<InsightsSummaryCategoryProps> = ({ plannedActions,  title }) => {
  //const [theme] = useState<Theme>(lavenderStyle)

  if (plannedActions.length === 0) {
    return null;
  }
  return (
    <Box pl="5px">
      <Text fontSize="lg" fontWeight="bold" color="gray.700">
        {title}
      </Text>
      {plannedActions.map((action, index) => (
        <Flex
          key={index}
          mt="2"
          p={2}
          shadow="md"
          borderRadius="md"
          alignItems="center"
          bgColor="#f5f6f8"
        >
        
          <Box ml={4}           bgColor="#f5f6f8">
            <Text fontWeight="semibold">{action}</Text>
          </Box>
        </Flex>
      ))}
    </Box>
  );
};

export default InsightsSummaryPlannedActionsCategory;

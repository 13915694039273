import {
    ChakraProvider,
} from "@chakra-ui/react"
import { BrowserRouter } from "react-router-dom"
import AppRoutes from "./routes/router"
import theme from "./styles/themes"
import { UserProfileContextProvider } from "./contexts/userProfileContextProvider"
import { FirebaseProvider } from "./contexts/firebaseProvider"

export const App = () => (
    <BrowserRouter>
        <ChakraProvider theme={theme}>
            <UserProfileContextProvider>
                    <FirebaseProvider>
                        <AppRoutes />
                    </FirebaseProvider>
            </UserProfileContextProvider>
        </ChakraProvider>
    </BrowserRouter>
)

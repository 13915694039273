// https://coolors.co/palette/1c1c1c-daddd8-ecebe4-eef0f2-fafaff

export interface Theme {
    name: string;
    colorScheme: string;
    colorScheme2: string;
    borderRadius: string;
    text1: string;
    text2: string;
    primaryColor: string;
    secondaryColor: string;
    thirdColor: string;
    fourthColor: string;
    fifthColor: string;
    messageTextColor: string;
    messageBackgroundColor: string;
    messageBorderColor: string;
    messageSenderTextColor: string;
    messageSenderBackgroundColor: string;
    messageBorderWidth: string;
    messageBoxColor: string;
    brainButtonColor: string;
    completementaryColor1?: string;
    completementaryColor2?: string;
    completementaryColor3?: string;
    completementaryColor4?: string;
  }


export const lavenderStyle : Theme = {
    name: 'lavenderStyle',
    colorScheme: 'lavenderStyle',
    colorScheme2: 'complementaryBrown',
    borderRadius: '5',
    text1: '#4b4b6f',
    text2: '#595983',
    primaryColor: '#4b4b6f',
    secondaryColor: '#DADDD8',
    thirdColor: '#bfbfe0',
    fourthColor: '#d9d9f7',
    fifthColor: '#f2f2ff',
    messageTextColor: 'white',
    messageBackgroundColor: '#616199',
    messageBorderColor: 'white',
    messageSenderTextColor: '#595983',
    messageSenderBackgroundColor: '#d9d9f7',
    messageBorderWidth: '1px',
    messageBoxColor: '#f2f2ff',
    brainButtonColor: '#cccce1',
    completementaryColor1: "#C4A1A1",
    completementaryColor2: "#A1C4A1",
    completementaryColor3: "#E0BFBF",
    completementaryColor4: "#BFE0BF",
  }